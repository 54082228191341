// src/App.js
import React from 'react';

import { BrowserRouter as Router } from 'react-router-dom';
import ListingPage from './Components/ListingPage/ListingPage';
import { MyProvider } from '../src/Components/context/MyContext';

function App() {
  return (
    <MyProvider>

    <Router>
      <div className="App">
    <ListingPage  />
      </div>  
    </Router>
    </MyProvider>

  );
}

export default App;
