import { listData } from "../../Lib/dummydata"
import Filter from "../filter/Filter"
import Card from "../card/Card";

function ListingPage(){

  const data = listData;
  return(
    <div className="flex  bg-slate-50  m-10">
      {/*------------- Listcontainer----------- */}

      <div className="flex flex-[1]">
        <div className="flex flex-col gap-10 justify-center">
          <Filter />
          {data.map(item =>(
            <Card key={item.id} item= {item}/>
          ))}
        </div>
      </div>
      
      
    {/*------------- Mapcontainer----------- */}

      <div className="flex flex-[5]">Other</div>
    </div>
  )
}

export default ListingPage