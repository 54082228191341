

import React from "react";
import { Link } from "react-router-dom";
import './card.css'
import { IoLocationSharp } from "react-icons/io5";
import { FaBed } from "react-icons/fa";
import { MdBathtub } from "react-icons/md";
import { IoDocumentOutline } from "react-icons/io5";
import { TfiCommentAlt } from "react-icons/tfi";
import { FaSackDollar } from "react-icons/fa6";


function Card({ item }) {
  return (
    <div className="flex flex-[2] gap-8 justify-center">

      <Link to={`/${item.id}`} className="imgContainer h-[200px] w-[300px] ">
        <img className="h-full w-full  rounded-xl" src={item.images[0]} alt="img" />
      </Link>

      <div className="flex flex-col flex-[3] gap-6 ">
        <h2 className="title font-bold text-lg" >
          <Link to={`/${item.id}`}>{item.title}</Link>
        </h2>

        <p className="address flex items-center gap-2 text-gray-700">
        <IoLocationSharp className="inline" /> 
        <span>{item.address}</span>
        </p>

        <p className="price flex items-center gap-2 text-gray-700 bg-primary-color w-fit p-1 rounded-md">
          <FaSackDollar /> {item.price}
        </p>

        <div className="bottom flex justify-between">

          <div className="features flex gap-10">
            <div className="feature  flex items-center gap-2 text-gray-700">
            <FaBed className="inline" />
            <span>{item.bedroom} bedroom</span>
            </div>

            <div className="feature  flex items-center gap-2 text-gray-700">
             <MdBathtub className="inline"/> 
              <span>{item.bathroom} bathroom</span>
            </div>

          </div>

          <div className="icons flex gap-10">
            <div className="icon px-1 rounded-md  border border-gray-200 cursor-pointer">
            <IoDocumentOutline className="inline"/>
             </div>
            <div className="icon px-1 rounded-md border border-gray-200 cursor-pointer">
              <TfiCommentAlt className="inline"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;

