import Button from '@mui/material/Button';
import { TbHomeSearch } from "react-icons/tb";
import './filter.css';


function Filter(){
  return(
    <div className="flex flex-col gap-4">

      <h1 className=' text-3xl '>Search results for <b>Properties</b></h1>

      <div className="Top">
        <div className="flex flex-col gap-1">
          <input className=' p-3 border border-primary-color rounded-md'
            type="text" 
            id="city" 
            name="city" 
            placeholder="City location" />
        </div>
      </div>

      
      <div className="flex gap-16">
        <div className="flex flex-col gap-1">
            <label className="text-md" htmlFor="type">Type</label>
            <select className='w-[100px] p-3 border border-primary-color rounded-md cursor-pointer' 
                id="type" 
                name="type"
            >
              <option  value="any">any</option>
              <option value="Buy">Buy</option>
              <option value="Buy">Rent</option>
            </select>
        </div>

        <div className="flex flex-col gap-1">
        <label className="text-md" htmlFor="property">Property</label>
            <select  className='w-[100px] p-3 border border-primary-color rounded-md cursor-pointer' 
              id="property" 
              name="property"
            >
              <option value="Any">any</option>
              <option value="Apartment">Apartment</option>
              <option value="Villa">Villa</option>
              <option value="Townhouse">Townhouse</option>
            </select>
        </div>

        <div className="flex flex-col gap-1">
        <label className="text-md" htmlFor="minPrice">Min Price</label>
            <input className='w-[100px] p-3 border border-primary-color rounded-md cursor-pointer' 
              type='number' 
              id="minPrice" 
              name="minPrice" 
              placeholder='any' 
            />
              
        </div>

        <div className="flex flex-col gap-1">
            <label className="text-md" htmlFor="maxPrice">Max Price</label>
            <input className='w-[100px] p-3 border border-primary-color rounded-md cursor-pointer' 
              type='number' 
              id="maxPrice" 
              name="maxPrice" 
              placeholder="any" 
            />
        </div>

        <div className="flex flex-col gap-1">
            <label className="text-md" htmlFor="bedroom">Bedroom</label>
            <input className='w-[100px] p-3 border border-primary-color rounded-md cursor-pointer' 
              type="text" 
              id="bedroom" 
              name="bedroom" 
              placeholder="any" 
            />
        </div>
      <Button className=''><TbHomeSearch className='text-3xl' /></Button>
      </div>
      
    </div>
  )
}

export default Filter